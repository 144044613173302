import React, { useEffect, useState } from 'react';
import { useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react'; // Import necessary components from Chakra UI
import fullLogo from '../icons/YTT5.jpg'; // Assuming the full logo is used in the image section
import companyLogo from '../icons/bnmrLogo.png'; // Assuming the full logo is used in the image section

const MQL = () => {
    const toast = useToast(); // Initialize the toast
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open state
  
    useEffect(() => {
      // toast({
      //   title: "Free Resource Sent!",
      //   description: "Check your email for the free resource.",
      //   status: "success",
      //   duration: 6000,
      //   isClosable: true,
      // });
  
      // Show the modal when the page loads
      setIsModalOpen(true);
  
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, [toast]);
  
    const onClose = () => setIsModalOpen(false); // Function to close the modal
  
  
  return (
    <div className="flex px-2 justify-center h-screen py-4 ">
  <style>
    {`
      .shadow-custom {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
      }

      .animate-fade-in {
        animation: fade-in 0.5s ease-in-out;
      }

      @keyframes fade-in {
        0% { opacity: 0; transform: translateY(10px); }
        100% { opacity: 1; transform: translateY(0); }
      }
    `}
  </style>

  <div className="w-full bg-white shadow-top-prominent rounded-3xl">
    <nav className="w-full bg-white px-2 flex justify-between items-center"
      style={{
        backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="flex items-center">
        <img
          src="https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cf3_new%20Benmore.svg"
          alt="Benmore Technologies"
          className="h-8 md:h-12"
        />
      </div>
    </nav>

    <div className="space-y-6 p-6 pt-0">
      <div
        className="text-center"
        style={{
          backgroundImage: 'url(https://cdn.prod.website-files.com/66da428db0e237a5894e0c53/66da428db0e237a5894e0cc1_desktop-hero-bgd.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h1 className="font-extrabold pt-6 text-2xl md:text-5xl md:px-[10%]">
          Congrats! You're Qualified for a <span className='text-[#1D54E2]'>Free Discovery Call</span>
        </h1>

        <h2 className="mt-4 text-lg md:text-2xl">
          <span className='font-bold'>Schedule your consultation:</span>
        </h2>
        <h4 className="mt-4 text-lg md:text-2xl">
          <span className=''>If there are no available times, please check back in 24 hours.</span>
        </h4>
      </div>

      <div className="w-full max-w-2xl px-4">
        <div className="calendly-inline-widget" data-url="https://calendly.com/d/ck2d-vpy-pm2/benmore-technologies-discovery-call" style={{ minWidth: '320px', height: '700px', width: '100%' }}></div>
      </div>
      <Modal isOpen={isModalOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent margin={2}>
          <ModalCloseButton />
          <ModalBody marginTop={6}>
             <strong className='mt-6'>Our team will give you a call in the next few minutes to discuss how we can help with your current situation.</strong>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  </div>
</div>

  );
};

export default MQL;
