import React, { useEffect, useState } from 'react';
import { useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react'; // Import necessary components from Chakra UI
import fullLogo from '../icons/YTT5.jpg'; // Assuming the full logo is used in the image section
import companyLogo from '../icons/bnmrLogo.png'; // Assuming the full logo is used in the image section

const Call = () => {
  const toast = useToast(); // Initialize the toast
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open state

  useEffect(() => {
    // toast({
    //   title: "Free Resource Sent!",
    //   description: "Check your email for the free resource.",
    //   status: "success",
    //   duration: 6000,
    //   isClosable: true,
    // });

    // Show the modal when the page loads
    setIsModalOpen(true);

    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [toast]);

  const onClose = () => setIsModalOpen(false); // Function to close the modal

  return (
    <div className="flex flex-col items-center justify-center min-h-screen pb-4 bg-gray-100 ">
      <div className="bg-[#2E5AF4] text-center w-full">
        <img src={companyLogo} alt="Company Logo" className="mx-auto h-10 my-2" />
      </div>
      <div className='px-2'>
      <h1 className="text-3xl font-bold text-center my-8">
        Have an idea?<br/><br/><span className='text-xl'>Schedule a free consultation to see how we can help.</span>
      </h1>
      <div className="w-full max-w-2xl px-4">
        {/* Calendly inline widget begin */}
        <div className="calendly-inline-widget" data-url="https://calendly.com/d/ck2d-vpy-pm2/benmore-technologies-discovery-call" style={{ minWidth: '320px', height: '700px', width: '100%' }}></div>
        {/* Calendly inline widget end */}
        <Modal isOpen={isModalOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent margin={2}>
          <ModalHeader>Congrats on taking the first step!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
             We've sent the free resource to your email.<br/><br/>
             <strong>Our team will give you a call in the next few minutes to see how else we can help with your software idea.</strong>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </div>
      </div>
    </div>
  );
};

export default Call;
