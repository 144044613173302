import React, { useEffect } from 'react';
import companyLogo from '../icons/bnmrLogo.png';

const Loom = () => {
  useEffect(() => {
    document.title = "Meeting Preparation with Benmore Technologies";
    document.querySelector('meta[name="description"]').setAttribute("content", "Prepare for your upcoming meeting with Benmore Technologies by watching this short video.");
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen pb-4 bg-gray-100">
      <div className="bg-[#2E5AF4] text-center w-full">
        <img src={companyLogo} alt="Company Logo" className="mx-auto h-10 my-2" />
      </div>
      <div className="px-2">
        <h1 className="text-3xl font-bold text-center my-8">
          Meeting with <span className="text-[#2E5AF4] whitespace-nowrap">Benmore Technologies</span> Confirmed!<br /><span className="text-xl">Please watch this video (1 min) to prepare for the call.</span>
        </h1>
        <div className="w-full max-w-2xl px-4">
          <div style={{ position: 'relative', paddingBottom: '56.07701564380265%', height: 0 }}>
            <iframe
              src="https://www.loom.com/embed/c7520fa46fd44b7fa94ef3431af02afb?sid=0dc54753-4b49-46a5-b75d-72ce8d304a9d"
              frameBorder="0"
              allow="fullscreen; webkitallowfullscreen; mozallowfullscreen"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loom;
